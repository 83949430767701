import Link from 'components/atoms/Link';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Icon from 'components/atoms/Icons';
import breakpoints from 'styles/breakpoints';
import RevealElement from 'components/atoms/RevealElement';
import Button from 'components/atoms/Button';
import { useCallback, useMemo } from 'react';
import { useLearnMoreStore } from './';

const IconCont = styled.div`
    width: 100%;
    .iconWrapper {
        border-color: rgb(var(--subdued-text-color));
        @media ${breakpoints.md} {
            height: ${props => (props.sm ? '169px' : '444px')};
        }
        @media ${breakpoints.maxSm} {
            height: ${props => (props.sm ? '150px' : '300px')};
        }
    }
    .label {
        svg {
            height: 4rem;
        }
    }
`;

const CTA = ({ cta, index }) => {
    const data = useMemo(() => {
        const {
            _uid,
            icon,
            buttonLink,
            buttonText,
            popupHeading,
            popupImage,
            popupText,
            popupButtons,
        } = { ...cta };
        return {
            index,
            _uid,
            icon,
            buttonLink,
            buttonText,
            popup: {
                popupHeading,
                popupImage:
                    popupImage?.filename && popupImage?.filename !== ''
                        ? popupImage.filename
                        : null,
                popupText,
                popupButtons,
            },
        };
    }, [cta]);

    const { setPopup, setPopupOpen } = useLearnMoreStore(({ setPopup, setPopupOpen }) => ({
        setPopup,

        setPopupOpen,
    }));

    const handleChangePopup = useCallback(
        popup => {
            setPopup(popup);
            setPopupOpen(true);
        },
        [setPopup]
    );

    return (
        <div
            className={`col-span-full sm:col-span-3 lg:col-span-4 ${
                data.index === 0 ? 'sm:row-span-2' : ''
            }`}
            key={data._uid}
        >
            <RevealElement delay={0.1 * data.index}>
                {data.buttonLink ? (
                    <Link href={data.buttonLink}>
                        <IconCont
                            className="block mb-8 lg:mb-0"
                            {...(data.index !== 0 && { sm: true })}
                        >
                            <Content icon={data.icon} buttonText={data.buttonText} />
                        </IconCont>
                    </Link>
                ) : data.popup?.popupHeading ||
                  data.popup?.popupImage ||
                  data.popup?.popupText ||
                  data.popup?.popupButtons?.length > 0 ? (
                    <IconCont
                        onClick={() => handleChangePopup(data.popup)}
                        as="button"
                        className="block mb-8 lg:mb-0"
                        {...(data.index !== 0 && { sm: true })}
                    >
                        <Content icon={data.icon} buttonText={data.buttonText} />
                    </IconCont>
                ) : (
                    <IconCont
                        className="block mb-8 lg:mb-0"
                        {...(data.index !== 0 && { sm: true })}
                    >
                        <Content icon={data.icon} buttonText={data.buttonText} />
                    </IconCont>
                )}
            </RevealElement>
        </div>
    );
};

const Content = ({ icon, buttonText }) => {
    const IconComponent = Icon[icon] || null;
    const DefaultIconComponent = Icon['FaqIcon'];

    return (
        <>
            <div className="iconWrapper border flex justify-center items-center mb-6">
                {IconComponent ? (
                    <IconComponent fill="currentColor" />
                ) : (
                    <DefaultIconComponent fill="currentColor" />
                )}
            </div>
            {buttonText && (
                <div className="label flex justify-between">
                    <Button className="w-full md:w-72" as="span" label={buttonText} />
                </div>
            )}
        </>
    );
};

CTA.propTypes = {
    cta: PropTypes.object,
    index: PropTypes.number,
};

Content.propTypes = {
    icon: PropTypes.string,
    buttonText: PropTypes.string,
};

export default CTA;
