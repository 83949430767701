import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import produce from 'immer';

const learnMoreStore = set => ({
    popupOpen: false,
    setPopupOpen: popupOpen =>
        set(
            produce(draft => {
                draft.popupOpen = popupOpen;
            })
        ),
    popup: null,

    setPopup: popup =>
        set(
            produce(draft => {
                draft.popup = popup;
            })
        ),
});

const useLearnMoreStore = create(devtools(learnMoreStore));

export default useLearnMoreStore;
