import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Link from 'components/atoms/Link';

import { BodyText, H2 } from 'components/atoms/Typography';
import { Section } from 'components/atoms/Container';
import RevealElement from 'components/atoms/RevealElement';
import Button from 'components/atoms/Button';
import * as Icon from 'components/atoms/Icons';
import { CTA, useLearnMoreStore } from './components';
import { Backdrop, Dialog, Fade } from '@material-ui/core';
import Image from 'components/atoms/Image';
import { ArrowRightIcon } from 'components/atoms/Icons';
import { storyblokEditable } from '@storyblok/react';

const LearnMore = ({ blok }) => {
    const data = useMemo(() => {
        const { title, text, ctas } = { ...blok };

        return {
            title,
            text,
            ctas,
        };
    }, [blok]);

    const { popup, setPopup, popupOpen, setPopupOpen } = useLearnMoreStore(
        ({ popup, setPopup, popupOpen, setPopupOpen }) => ({
            popup,
            setPopup,
            popupOpen,
            setPopupOpen,
        })
    );

    const handleClosePopup = useCallback(() => {
        setPopup(null);
        setPopupOpen(false);
    }, [setPopup]);

    return (
        <Section className="container lg:py-7" {...storyblokEditable(blok)}>
            <div className="grid grid-cols-6 lg:grid-cols-12 gap-x-10 lg:gap-y-10">
                <div className="col-span-full lg:col-span-4 mb-7 lg:sticky lg:top-24">
                    {data?.title && (
                        <H2 className="mb-6 w-11/12 lg:w-full">
                            <RevealElement>{data.title}</RevealElement>
                        </H2>
                    )}
                    {data?.text && (
                        <RevealElement delay={0.05}>
                            <BodyText>{data.text}</BodyText>
                        </RevealElement>
                    )}
                </div>
                <div className="col-span-full lg:col-span-8 grid grid-cols-6 lg:grid-cols-8 gap-x-10 lg:gap-y-10">
                    {data?.ctas?.map((cta, index) => (
                        <CTA key={cta._uid} cta={cta} index={index} />
                    ))}
                </div>
            </div>
            {popup && (
                <Dialog
                    open={popupOpen}
                    onClose={handleClosePopup}
                    aria-label={popup?.popupHeading || 'Modal label'}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    PaperProps={{
                        square: true,
                    }}
                >
                    <Fade in={popupOpen}>
                        <div className="w-full max-w-2xl outline-none">
                            <div className="relative p-6 lg:p-8 bg-light">
                                {popup?.popupImage && (
                                    <Image
                                        className="w-full h-auto mb-8"
                                        src={popup.popupImage}
                                        alt={`${popup.popupHeading} - featured image.`}
                                        loading="lazy"
                                        width={436}
                                        height={325}
                                    />
                                )}
                                {popup?.popupHeading && (
                                    <H2 className="mb-3" as="h1">
                                        {popup.popupHeading}
                                    </H2>
                                )}
                                {popup?.popupText && (
                                    <BodyText className="line-clamp-4 mb-8">
                                        {popup.popupText}
                                    </BodyText>
                                )}
                                <div className="grid gap-4">
                                    {popup?.popupButtons?.map(item => (
                                        <Link key={item._uid} href={item?.button_url || '#'}>
                                            <Button
                                                as="span"
                                                label={item.button_text}
                                                onClick={handleClosePopup}
                                                icon={<ArrowRightIcon />}
                                            />
                                        </Link>
                                    ))}
                                </div>
                                <span className="absolute right-0 top-0">
                                    <Button
                                        type="icon"
                                        icon={<Icon.CloseIcon fill="currentColor" />}
                                        paddingMobile="0.3125rem"
                                        onClick={handleClosePopup}
                                        buttonType="button"
                                    />
                                </span>
                            </div>
                        </div>
                    </Fade>
                </Dialog>
            )}
        </Section>
    );
};

LearnMore.propTypes = {
    blok: PropTypes.oneOfType([PropTypes.object]),
};

export default LearnMore;
